import React, { useEffect } from "react";
import styled from "styled-components";
import { Redirect, Route } from "react-router-dom";
import SmartFarmListPage from "./pages/SmartFarmListPage";
import SmartFarmDetailPage from "./pages/SmartFarmDetailPage";
import GinSmartFarmPage from "./pages/GinSmartFarmPage";
import CbfPage from "./pages/CbfPage";
import { SmartFarmIDContextProvider } from "./contexts/SmartFarmIDContext";
import { useScreenDispatchContext } from "./contexts/ScreenContext";

const AppBlock = styled.div`
  width: 100%;
  height: 100%;
  & * {
    box-sizing: border-box;
  }
`;

function App() {
  const dispatch = useScreenDispatchContext();

  const handleResize = () => {
    const screen = {
      screenWidth: window.screen.availWidth,
      screenHeight:
        window.screen.availHeight - (window.outerHeight - window.innerHeight),
    };

    if (screen.screenHeight > window.screen.availHeight) {
      screen.screenHeight = window.screen.availHeight;
    }

    dispatch({
      type: "RESIZE",
      screen: screen,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <AppBlock>
      <SmartFarmIDContextProvider>
        <Route path="/" component={SmartFarmListPage} exact />
        <Route
          path="/detail/:smartFarmID"
          component={SmartFarmDetailPage}
          exact
        />
        <Route path="/cbf" component={CbfPage} />
        <Route path="/gin-smartfarm" component={GinSmartFarmPage} />
        <Redirect path="*" to="/" />
      </SmartFarmIDContextProvider>
    </AppBlock>
  );
}

export default App;
