import React from "react";
import styled from "styled-components";
import { getSmartFarm } from "../../data/smartFarms";
import worldWideWeb from "../../images/world_wide_web.png";
import { useSmartFarmIDContext } from "../../contexts/SmartFarmIDContext";
import { DeviceIDs } from "../../hooks/useDeviceIDs";

const NavigationBarBlock = styled.div<{ onlyTitle?: boolean }>`
  width: ${(props) => props.theme.screenWidth + "px"};
  height: ${(props) =>
    props.onlyTitle
      ? props.theme.screenHeight * 0.06 + "px"
      : props.theme.screenHeight * 0.12 + "px"};
  padding: 0;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px gray;
`;

const UpperDiv = styled.div<{ onlyTitle?: boolean }>`
  width: 100%;
  height: ${(props) => (props.onlyTitle ? "100%" : "50%")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-weight: bold;
`;

const SmartFarmName = styled.div``;

const SmartFarmLocation = styled.div``;

const LowerDiv = styled.div<{ onlyTitle?: boolean }>`
  width: 100%;
  height: 50%;
  display: ${(props) => (props.onlyTitle ? "none" : "flex")};
  flex-direction: row;
  justify-content: space-between;
`;

const Navigation = styled.div<{ power?: boolean }>`
  width: ${(props) => props.theme.screenWidth * 0.1 + "px"};
  height: ${(props) => props.theme.screenHeight * 0.05 + "px"};
  margin: 0 5px 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px gray;
  border: 1px solid gray;
  background-color: ${(props) => (props.power ? "#ffffc5" : "white")};

  &:hover {
    background-color: #ffffc5;
  }

  @media ${(props) => props.theme.mobile} {
    width: ${(props) => props.theme.screenWidth * 0.25 + "px"};
    font-size: 0.9rem;
  }
`;

const ExternalLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const Img = styled.img`
  width: auto;
  height: ${(props) => props.theme.screenHeight * 0.04 + "px"};
`;

interface NavigationBarProps {
  deviceIDs?: DeviceIDs;
  navigationStatus?: number;
  onClick?: (category: string) => void;
}

function NavigationBar({
  deviceIDs = { nabs: [], oas: [], envs: [], acs: [] },
  navigationStatus = 0,
  onClick = () => {},
}: NavigationBarProps) {
  const smartFarmID = useSmartFarmIDContext();
  const smartFarm = getSmartFarm(smartFarmID);

  const onlyTitle: boolean = deviceIDs.nabs.length === 0;

  return (
    <NavigationBarBlock onlyTitle={onlyTitle}>
      <UpperDiv onlyTitle={onlyTitle}>
        <div></div>
        <SmartFarmName>{!smartFarm ? "loading" : smartFarm.name}</SmartFarmName>
        <SmartFarmLocation>
          {!smartFarm ? "loading" : smartFarm.location}
        </SmartFarmLocation>
        <ExternalLink href={!smartFarm ? "" : smartFarm.url} target="_blank">
          <Img src={worldWideWeb} />
        </ExternalLink>
        <div></div>
      </UpperDiv>
      <LowerDiv onlyTitle={onlyTitle}>
        <Navigation
          onClick={() => onClick("nabs")}
          power={navigationStatus === 1 ? true : false}
        >
          양액기
        </Navigation>
        {deviceIDs.oas.length !== 0 && (
          <Navigation
            onClick={() => onClick("oas")}
            power={navigationStatus === 2 ? true : false}
          >
            재배기 제어기
          </Navigation>
        )}
        {deviceIDs.envs.length !== 0 && (
          <Navigation
            onClick={() => onClick("envs")}
            power={navigationStatus === 3 ? true : false}
          >
            환경 측정기
          </Navigation>
        )}
        {deviceIDs.acs.length !== 0 && (
          <Navigation
            onClick={() => onClick("acs")}
            power={navigationStatus === 4 ? true : false}
          >
            에어컨 제어기
          </Navigation>
        )}
      </LowerDiv>
    </NavigationBarBlock>
  );
}

export default NavigationBar;
