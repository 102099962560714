import React, { Suspense, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import loadable from "@loadable/component";
import NavigationBar from "../components/smartFarmDetail/NavigationBar";
import useDeviceIDs from "../hooks/useDeviceIDs";
import { useSmartFarmIDDispatchContext } from "../contexts/SmartFarmIDContext";

const SmartFarmDetailPageBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  width: ${(props) => props.theme.screenWidth + "px"};
  height: ${(props) => props.theme.screenHeight * 0.88 + "px"};
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  @media ${(props) => props.theme.mobile} {
  }
`;

const DeviceStatusComponent = loadable(
  () => import("../components/smartFarmDetail/DeviceStatusComponent")
);
const fallback = () => <div>...Loading...</div>;

/**
 * NOTE navigationStatus 메모
 *
 * 1 이면 양액기
 * 2 이면 재배기 제어기
 * 3 이면 환경 측정기
 * 4 이면 에어컨 제어기
 */
function SmartFarmDetailPage() {
  const { smartFarmID } = useParams<{ smartFarmID: string }>();
  const dispatch = useSmartFarmIDDispatchContext();
  const deviceIDs = useDeviceIDs(Number(smartFarmID));

  useEffect(() => {
    dispatch({ type: "SMARTFARM_ID", smartFarmID: Number(smartFarmID) });

    // eslint-disable-next-line
  }, []);

  const [navigationStatus, setNavigationStatus] = useState(0);
  const navigate = (category: string) => {
    switch (category) {
      case "nabs":
        setNavigationStatus(1);
        break;
      case "oas":
        setNavigationStatus(2);
        break;
      case "envs":
        setNavigationStatus(3);
        break;
      case "acs":
        setNavigationStatus(4);
        break;
    }
  };

  return (
    <SmartFarmDetailPageBlock>
      <NavigationBar
        deviceIDs={deviceIDs}
        onClick={navigate}
        navigationStatus={navigationStatus}
      />
      <Contents>
        {navigationStatus === 1 && (
          <Suspense fallback={fallback}>
            <DeviceStatusComponent
              deviceIDs={deviceIDs.nabs.map((element) => element.id)}
            />
          </Suspense>
        )}
        {navigationStatus === 2 && (
          <Suspense fallback={fallback}>
            <DeviceStatusComponent
              deviceIDs={deviceIDs.oas.map((element) => element.id)}
            />
          </Suspense>
        )}
        {navigationStatus === 3 && (
          <Suspense fallback={fallback}>
            <DeviceStatusComponent
              deviceIDs={deviceIDs.envs.map((element) => element.id)}
            />
          </Suspense>
        )}
        {navigationStatus === 4 && (
          <Suspense fallback={fallback}>
            <DeviceStatusComponent
              deviceIDs={deviceIDs.acs.map((element) => element.id)}
            />
          </Suspense>
        )}
      </Contents>
    </SmartFarmDetailPageBlock>
  );
}

export default SmartFarmDetailPage;
