import React from "react";
import styled from "styled-components";
import SideBar from "../commons/SideBar";
import SmartFarmComponent from "../components/smartFarmList/SmartFarmComponent";
import smartFarms, { SmartFarm } from "../data/smartFarms";

const SmartFarmListPageBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  width: ${(props) => props.theme.screenWidth + "px"};
  height: ${(props) => props.theme.screenHeight * 0.94 + "px"};
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  @media ${(props) => props.theme.mobile} {
  }
`;

const Title = styled.div`
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: x-large;
`;

const Table = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
  }
`;

const Column = styled.div`
  width: ${(props) => props.theme.screenWidth * 0.1428 + "px"};
  height: auto;
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.mobile} {
    width: 100%;
    flex-direction: row;
  }
`;

function SmartFarmListPage() {
  const makeColumn = (arr: SmartFarm[]) => {
    const result: JSX.Element[] = [];

    const unit = 4;
    const length = arr.length;
    for (let i = unit; i < length + unit; i += unit) {
      const tmp = arr.slice(i - unit, i);

      result.push(
        <Column key={i}>
          {tmp.map((element) => (
            <SmartFarmComponent key={element.smartFarmID} smartFarm={element} />
          ))}
        </Column>
      );
    }

    return result;
  };

  return (
    <SmartFarmListPageBlock>
      <SideBar url="/" />
      <Contents>
        <Title>스마트팜 리스트</Title>
        <Table>{makeColumn(smartFarms)}</Table>
      </Contents>
    </SmartFarmListPageBlock>
  );
}

export default SmartFarmListPage;
