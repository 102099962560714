import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Box from "../../commons/Box";
import { SmartFarm } from "../../data/smartFarms";

const SmartFarmComponentBlock = styled.div`
  height: 25%;
  padding: 0.5rem;

  &:hover {
    background-color: #ffffc5;
  }

  @media ${(props) => props.theme.mobile} {
    height: auto;
  }
`;

const LinkNoColor = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const CenterTextBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

interface SmartFarmComponentProps {
  smartFarm?: SmartFarm;
}

function SmartFarmComponent({
  smartFarm = { name: "이름", smartFarmID: 0, location: "위치", url: "" },
}: SmartFarmComponentProps) {
  const { name, smartFarmID, location } = smartFarm;

  const getUrl = (smartFarmID: number) => {
    const CBF = 99;
    const GIN_SMART_FARM = 999;

    switch (smartFarmID) {
      case CBF: {
        return {
          pathname: "/cbf",
          state: { smartFarmID: smartFarmID },
        };
      }
      case GIN_SMART_FARM:
        return {
          pathname: "/gin-smartfarm",
          state: { smartFarmID: smartFarmID },
        };
      default:
        return "/detail/" + smartFarmID;
    }
  };

  return (
    <SmartFarmComponentBlock>
      <LinkNoColor to={getUrl(smartFarmID)}>
        <CenterTextBox width={100} height={100}>
          {name}
          <br />
          {"(" + location + ")"}
        </CenterTextBox>
      </LinkNoColor>
    </SmartFarmComponentBlock>
  );
}

export default SmartFarmComponent;
