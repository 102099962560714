import { useState, useEffect } from "react";
import { DeviceProps, dynamicImportDeviceIDs } from "../data/deviceIDs";

export type DeviceIDs = {
  nabs: DeviceProps[];
  oas: DeviceProps[];
  envs: DeviceProps[];
  acs: DeviceProps[];
};

function useDeviceIDs(smartFarmID: number) {
  const [deviceIDs, setDeviceIDs] = useState<DeviceIDs>({
    nabs: [],
    oas: [],
    envs: [],
    acs: [],
  });

  const getDeviceIDs = () => {
    dynamicImportDeviceIDs(smartFarmID).then((obj) => {
      const { nabs, oas, envs, acs } = obj;
      const tmp: DeviceIDs = {
        nabs: nabs,
        oas: oas,
        envs: envs,
        acs: acs,
      };
      setDeviceIDs(tmp);
    });
  };

  useEffect(() => {
    getDeviceIDs();

    // eslint-disable-next-line
  }, []);

  return deviceIDs;
}

export default useDeviceIDs;
