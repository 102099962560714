import { useReducer, createContext, Dispatch, useContext } from "react";

export const SmartFarmIDContext = createContext<number | undefined>(undefined);

type Action = { type: "SMARTFARM_ID"; smartFarmID: number };

export const SmartFarmIDDispatchContext = createContext<
  Dispatch<Action> | undefined
>(undefined);

const reducer = (state: number, action: Action) => {
  switch (action.type) {
    case "SMARTFARM_ID":
      return action.smartFarmID;
    default:
      return state;
  }
};

export function SmartFarmIDContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [smartFarmID, dispatch] = useReducer(reducer, 0);

  return (
    <SmartFarmIDDispatchContext.Provider value={dispatch}>
      <SmartFarmIDContext.Provider value={smartFarmID}>
        {children}
      </SmartFarmIDContext.Provider>
    </SmartFarmIDDispatchContext.Provider>
  );
}

export const useSmartFarmIDContext = () => {
  const smartFarmID = useContext(SmartFarmIDContext);
  if (typeof smartFarmID === "undefined")
    throw new Error("SmartFarmIDContext Provider not found !");

  return smartFarmID;
};

export const useSmartFarmIDDispatchContext = () => {
  const dispatch = useContext(SmartFarmIDDispatchContext);
  if (typeof dispatch === "undefined")
    throw new Error("SmartFarmIDDispatchContext Provider not found !");

  return dispatch;
};
