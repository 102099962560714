export type SmartFarm = {
  name: string;
  smartFarmID: number;
  location: string;
  url: string;
};

const smartFarms: SmartFarm[] = [
  {
    name: "채소의 완성",
    smartFarmID: 1,
    location: "경남 양산",
    url: "https://iot-vegetable-complete.sangsang.farm/",
  },
  {
    name: "샤브올",
    smartFarmID: 2,
    location: "부산광역시",
    url: "https://iot-shabuall.sangsang.farm/",
  }, // NOTE 내부 재배실이 2, 외부 재배실이 3
  {
    name: "대구 한의대",
    smartFarmID: 4,
    location: "경북 경산",
    url: "https://iot-dhu.sangsang.farm/",
  },
  {
    name: "원고개",
    smartFarmID: 5,
    location: "대구광역시",
    url: "https://iot-onegogae.sangsang.farm/",
  },
  {
    name: "자연나라",
    smartFarmID: 6,
    location: "경기도 이천",
    url: "https://iot-nature.sangsang.farm/",
  },
  {
    name: "경상북도 농업기술원",
    smartFarmID: 7,
    location: "경북 안동",
    url: "https://iot-ibr.sangsang.farm/",
  },
  {
    name: "샐픽",
    smartFarmID: 8,
    location: "부산 부산진구",
    url: "https://iot-salpick.sangsang.farm/",
  },
  {
    name: "춘천 바이오 산업진흥원",
    smartFarmID: 99,
    location: "강원도 춘천",
    url: "https://iot-cbf.sangsang.farm/",
  }, // 몰라서 임의로 99라 함
  {
    name: "진 스마트팜",
    smartFarmID: 999,
    location: "전북 익산",
    url: "https://iot-jin.sangsang.farm/",
  }, // 몰라서 임의로 999라 함
];

export const getSmartFarm = (smartFarmID: number) => {
  const index = smartFarms.findIndex((smartFarm) => {
    return smartFarmID === smartFarm.smartFarmID;
  });

  return smartFarms[index];
};

export default smartFarms;
