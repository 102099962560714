import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import mainLogo from "../images/main-logo.png";

const SideBarBlock = styled.div`
  width: ${(props) => props.theme.screenWidth + "px"};
  height: ${(props) => props.theme.screenHeight * 0.06 + "px"};
  padding: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px gray;
`;

const LogoImage = styled.img`
  padding-left: 1%;
  width: auto;
  height: 70%;
  cursor: pointer;
`;

interface SideBarProps {
  url?: string;
  children?: React.ReactNode;
}

function SideBar({ url = "/home", children }: SideBarProps) {
  const history = useHistory();
  return (
    <SideBarBlock>
      <LogoImage
        src={mainLogo}
        alt="상상텃밭"
        onClick={() => history.push(url)}
      />
      {children}
    </SideBarBlock>
  );
}

export default SideBar;
