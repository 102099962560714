import React, { useState, Suspense } from "react";
import styled from "styled-components";
import loadable from "@loadable/component";
import useDeviceInfo from "../../../hooks/useDeviceInfo";
import connected from "../../../images/connected.png";
import disconnected from "../../../images/disconnected.png";

const DetailStatusBlock = styled.tr<{ drawer?: boolean }>`
  height: ${(props) => props.theme.screenHeight * 0.1 + "px"};
  text-align: center;
  background-color: ${(props) => (props.drawer ? "#ffffc5" : "")};

  &:hover {
    background-color: #ffffc5;
    cursor: pointer;
  }
`;

const Img = styled.img`
  height: ${(props) => props.theme.screenHeight * 0.07 + "px"};

  @media ${(props) => props.theme.mobile} {
    height: ${(props) => props.theme.screenHeight * 0.04 + "px"};
  }
`;

const Td = styled.td`
  @media ${(props) => props.theme.mobile} {
    font-size: 0.9rem;
  }
`;

const Status = styled(Td)``;

const Name = styled(Td)``;

const Location = styled(Td)``;

const UpdatedAt = styled(Td)``;

interface DetailStatusProps {
  deviceID?: string;
}

const fallback = () => <div>...loading...</div>;

const NA400 = loadable(() => import("./GinDetailStatus/NA400"));
const NA500 = loadable(() => import("./GinDetailStatus/NA500"));
const NA600 = loadable(() => import("./GinDetailStatus/NA600"));
const NAB200 = loadable(() => import("./GinDetailStatus/NAB200"));
const OA400 = loadable(() => import("./GinDetailStatus/OA400"));
const NA700 = loadable(() => import("./GinDetailStatus/NA700"));
const NAB100 = loadable(() => import("../../cbf/NAB100"));
const LED100 = loadable(() => import("../../cbf/LED100"));

function DetailStatus({ deviceID = "DefaultID" }: DetailStatusProps) {
  const { connection, name, location, updatedAt } = useDeviceInfo(deviceID);

  const toKorTime = (time: string) =>
    new Date(time).toLocaleString("ko-KR", { timeZone: "Asia/Seoul" });

  const [drawer, setDrawer] = useState(false);
  const onClick = () => {
    const newState = !drawer;
    setDrawer(newState);
  };

  const renderComponent = (deviceID: string) => {
    const idLength = deviceID.length - 4;
    const category = deviceID.slice(0, idLength);

    switch (category) {
      case "NA400":
        return <NA400 deviceID={deviceID} />;
      case "NA500":
        return <NA500 deviceID={deviceID} />;
      case "NA600":
        return <NA600 deviceID={deviceID} />;
      case "NAB200":
        return <NAB200 deviceID={deviceID} />;
      case "OA400":
        return <OA400 deviceID={deviceID} />;
      case "NA700":
        return <NA700 deviceID={deviceID} />;
      case "NAB100-CBF":
        return <NAB100 deviceID={deviceID} />;
      case "LED100-CBF":
        return <LED100 deviceID={deviceID} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <DetailStatusBlock drawer={drawer} onClick={onClick}>
        <Status>
          <Img src={connection ? connected : disconnected} />
        </Status>
        <Name>{name === null ? "없음" : name}</Name>
        <Location>{location === null ? "없음" : location}</Location>
        <UpdatedAt>{toKorTime(updatedAt)}</UpdatedAt>
      </DetailStatusBlock>
      <Suspense fallback={fallback}>
        {drawer && renderComponent(deviceID)}
      </Suspense>
    </>
  );
}

export default DetailStatus;
