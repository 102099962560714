import React from "react";
import styled from "styled-components";
import GinDetailStatus from "./GinDeviceStatusComponent/GinDetailStatus";

const GinDeviceStatusComponentBlock = styled.div`
  width: 100%;
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
`;

interface GinDeviceStatusComponentProps {
  deviceIDs?: string[];
}

function GinDeviceStatusComponent({
  deviceIDs = [],
}: GinDeviceStatusComponentProps) {
  return (
    <GinDeviceStatusComponentBlock>
      <Table>
        <thead>
          <tr>
            <th>상태</th>
            <th>이름</th>
            <th>위치</th>
            <th>마지막 업데이트</th>
          </tr>
        </thead>
        <tbody>
          {deviceIDs.map((deviceID) => (
            <GinDetailStatus key={deviceID} deviceID={deviceID} />
          ))}
        </tbody>
      </Table>
    </GinDeviceStatusComponentBlock>
  );
}

export default GinDeviceStatusComponent;
