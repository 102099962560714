import { ThemeContext, ThemeProvider } from "styled-components";
import { useReducer, createContext, Dispatch, useContext } from "react";

type Theme = {
  screenWidth: number;
  screenHeight: number;
  mobile: string;
};

type Action = {
  type: "RESIZE";
  screen: { screenWidth: number; screenHeight: number };
};

export const ScreenDispatchContext = createContext<
  Dispatch<Action> | undefined
>(undefined);

const reducer = (state: Theme, action: Action) => {
  switch (action.type) {
    case "RESIZE":
      return {
        ...state,
        screenWidth: action.screen.screenWidth,
        screenHeight: action.screen.screenHeight,
      };
    default:
      return state;
  }
};

export function ScreenContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = useReducer(reducer, {
    screenWidth: window.screen.availWidth,
    screenHeight:
      window.screen.availHeight - (window.outerHeight - window.innerHeight),
    mobile: "(max-width: 770px)",
  });

  return (
    <ScreenDispatchContext.Provider value={dispatch}>
      <ThemeProvider theme={state}>{children}</ThemeProvider>
    </ScreenDispatchContext.Provider>
  );
}

export const useScreenContext = () => {
  const screen = useContext(ThemeContext);
  if (!screen) throw new Error("Screen Context Provider not found !");

  return screen;
};

export const useScreenDispatchContext = () => {
  const dispatch = useContext(ScreenDispatchContext);
  if (!dispatch) throw new Error("Screen Context Provider not found !");

  return dispatch;
};
