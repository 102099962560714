import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useSmartFarmIDDispatchContext } from "../contexts/SmartFarmIDContext";
import NavigationBar from "../components/smartFarmDetail/NavigationBar";
import GinDeviceStatusComponent from "../components/ginSmartFarm/GinDeviceStatusComponent";
import * as deviceIDs from "../data/gin";

const GinSmartFarmPageBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  width: ${(props) => props.theme.screenWidth + "px"};
  height: ${(props) => props.theme.screenHeight * 0.88 + "px"};
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  @media ${(props) => props.theme.mobile} {
  }
`;

function GinSmartFarmPage() {
  const dispatch = useSmartFarmIDDispatchContext();
  const location = useLocation();

  const ginIDs: string[] = [
    deviceIDs.na400,
    deviceIDs.na500,
    deviceIDs.na600,
    deviceIDs.nab200,
    deviceIDs.oa400,
  ].concat(deviceIDs.na700s);

  useEffect(() => {
    if (location.state) {
      const state = location.state as any;

      dispatch({
        type: "SMARTFARM_ID",
        smartFarmID: Number(state.smartFarmID),
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <GinSmartFarmPageBlock>
      <NavigationBar />
      <Contents>
        <GinDeviceStatusComponent deviceIDs={ginIDs} />
      </Contents>
    </GinSmartFarmPageBlock>
  );
}

export default GinSmartFarmPage;
