import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useSmartFarmIDDispatchContext } from "../contexts/SmartFarmIDContext";
import NavigationBar from "../components/smartFarmDetail/NavigationBar";
import GinDeviceStatusComponent from "../components/ginSmartFarm/GinDeviceStatusComponent";
import * as deviceIDs from "../data/cbf";

const CbfPageBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  width: ${(props) => props.theme.screenWidth + "px"};
  height: ${(props) => props.theme.screenHeight * 0.88 + "px"};
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  @media ${(props) => props.theme.mobile} {
  }
`;

function CbfPage() {
  const dispatch = useSmartFarmIDDispatchContext();
  const location = useLocation();

  const cbfIDs: string[] = [deviceIDs.nab100, deviceIDs.led100];

  useEffect(() => {
    const state = location.state as any;

    dispatch({ type: "SMARTFARM_ID", smartFarmID: Number(state.smartFarmID) });

    // eslint-disable-next-line
  }, []);

  return (
    <CbfPageBlock>
      <NavigationBar />
      <Contents>
        <GinDeviceStatusComponent deviceIDs={cbfIDs} />
      </Contents>
    </CbfPageBlock>
  );
}

export default CbfPage;
