export const na400 = "NA400-001";

export const na500 = "NA500-001";

export const na600 = "NA600-001";

export const na700s = ["NA700-001", "NA700-002", "NA700-003"];

export const nab200 = "NAB200-001";

export const oa400 = "OA400-001";
