export type DeviceProps = {
  name: string;
  id: string;
};

export type DeviceIDs = {
  nabs: DeviceProps[];
  oas: DeviceProps[];
  envs: DeviceProps[];
  acs: DeviceProps[];
};

export const dynamicImportDeviceIDs = (smartFarmID: number) => {
  switch (smartFarmID) {
    case 1:
      return import("./vegecompl");
    case 2:
      return import("./shabuall");
    case 4:
      return import("./dhu");
    case 5:
      return import("./onegogae");
    case 6:
      return import("./nature");
    case 7:
      return import("./ibr");
    case 8:
      return import("./salpick");
    default:
      return new Promise<any>(() => {
        return {
          nabs: [],
          oas: [],
          envs: [],
          acs: [],
        };
      });
  }
};
