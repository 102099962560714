import { useState, useEffect } from "react";
import { deviceAPI, ginDeviceAPI } from "@sangsangfarm/iot-clientside-api";

function useDeviceInfo(deviceID: string) {
  type DeviceInfo = {
    connection: boolean;
    name: string;
    location: string;
    updatedAt: string;
  };

  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    connection: false,
    name: "loading",
    location: "loading",
    updatedAt: "loading",
  });

  const getDeviceInfo = () => {
    const deviceCategory = deviceID.split("-")[0];
    let api;
    switch (deviceCategory) {
      case "NA400":
      case "NA500":
      case "NA600":
      case "NA700":
      case "NAB200":
      case "OA400":
        api = ginDeviceAPI;
        break;
      default:
        api = deviceAPI;
    }

    api.getDeviceInfo(deviceID).then((resolvedData) => {
      if (resolvedData !== null) {
        const {
          connection,
          name,
          location,
          updatedAt,
        }: DeviceInfo = resolvedData;

        const response: DeviceInfo = {
          connection: connection,
          name: name,
          location: location,
          updatedAt: updatedAt,
        };

        setDeviceInfo(response);
      }
    });
  };

  useEffect(() => {
    getDeviceInfo();

    const interval = setInterval(getDeviceInfo, 1000 * 60);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line
  }, []);

  return deviceInfo;
}

export default useDeviceInfo;
